import "styles/pages/page-ne-monuments.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "components/Breadcrumbs";

const NorthEastMonumentsPage = ({ pageContext }) => {
	const pageNEMonuments = pageContext.pageContent;
	return (
		<Layout headerColor="#FFCE2E">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/nemonuments-bg-main.jpg").default}
			/>
			<Breadcrumbs
				prevLocationLink="/zabytki"
				prevLocationLabel="Zabytki"
				currentLocationLabel="Zabytki Półnoć Wschód"
			/>
			<section className="surin">
				<div className="container">
					<h2 className="section-title">
						{pageNEMonuments.section1Title}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNEMonuments.section1Subtitle}
								desc={pageNEMonuments.section1Text}
								addres={pageNEMonuments.section1Address}
								phone={pageNEMonuments.section1Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageNEMonuments.section1Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="phanom">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageNEMonuments.section2Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNEMonuments.section2Title}
								desc={pageNEMonuments.section2Text}
								addres={pageNEMonuments.section2Address}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="miasto-pillar">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNEMonuments.section3Title}
								desc={pageNEMonuments.section3Text}
								addres={pageNEMonuments.section3Address}
								hours={pageNEMonuments.section3Hours}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageNEMonuments.section3Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="phraya-surin">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageNEMonuments.section4Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 ">
							<TextWithSymbol
								title={pageNEMonuments.section4Title}
								desc={pageNEMonuments.section4Text}
								addres={pageNEMonuments.section4Address}
								hours={pageNEMonuments.section4Hours}
								phone={pageNEMonuments.section4Phone}
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="ban-prasat">
				<div className="container">
					<h2 className="section-title">
						{pageNEMonuments.section5Title}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNEMonuments.section5Subtitle}
								desc={pageNEMonuments.section5Text}
								addres={pageNEMonuments.section5Address}
								hours={pageNEMonuments.section5Hours}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageNEMonuments.section5Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default NorthEastMonumentsPage;
